import ChangeLayoutButton from './layouts/ChangeLayoutButton.js'
import ProfileButton from './auth/ProfileButton.js'
import MoreLayouts from './layouts/MoreLayouts.js'
import layoutButtons from './layouts/layoutButtons.js'
import { db, getAuthUser } from '../firebase.js'
import { doc, updateDoc } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import ModalUpgrade from './modals/ModalUpgrade.js'
import { useIsPaidUser } from './util/useIsPaidUser.js'
import ModalCustomLayout from './modals/ModalCustomLayout.js'
import ModalShareDashboard from './modals/ModalShareDashboard.js'
import ModalCustomizeDashboard from './modals/ModalCustomizeDashboard.js'

export default function ScreenFooter ({ screenId, screen, onChangeLayout, onHideFooter, disabled = false }) {
  const [user, setUser] = useState(null)
  const [showUpgradeModal, setShowUpgradeModal] = useState(false)
  const [showCustomLayoutModal, setShowCustomLayoutModal] = useState(false)
  const [savingCustomLayout, setSavingCustomLayout] = useState(false)
  const [showShareDashboardModal, setShowShareDashboardModal] = useState(false)
  const [showCustomizeDashboardModal, setShowCustomizeDashboardModal] = useState(false)

  const isPaidUser = useIsPaidUser()

  // Load user from firebase
  useEffect(() => { loadUser() })
  async function loadUser () {
    const user = await getAuthUser()
    setUser(user)
  }

  async function claimSpace () {
    const user = await getAuthUser()
    try {
      const docRef = doc(db, 'screens', screenId)
      await updateDoc(docRef, {
        updated: new Date(),
        uid: user.uid,
      })
    } catch (err) {
      console.error('Error updating document: ', err)
    }
  }

  async function saveCustomLayout (layout) {
    setSavingCustomLayout(true)
    try {
      const docRef = doc(db, 'screens', screenId)
      await updateDoc(docRef, {
        updated: new Date(),
        customLayout: layout,
        layout: 'layoutCustom',
      })

      setSavingCustomLayout(false)
      return setShowCustomLayoutModal(false)
    } catch (err) {
      setSavingCustomLayout(false)
      console.error('Error updating document: ', err)
    }
  }

  async function saveShareSetting (setting, update) {
    // setSavingCustomLayout(true)
    try {
      const docRef = doc(db, 'screens', screenId)
      await updateDoc(docRef, {
        updated: new Date(),
        permissions: {
          ...screen.permissions || {},
          [setting]: update,
        },
      })

      // setSavingCustomLayout(false)
      // return setShowCustomLayoutModal(false)
    } catch (err) {
      // setSavingCustomLayout(false)
      console.error('Error updating document: ', err)
    }
  }

  async function saveCustomizationSetting (settings) {
    try {
      const docRef = doc(db, 'screens', screenId)
      await updateDoc(docRef, {
        updated: new Date(),
        settings: {
          ...settings,
        },
      })

    } catch (err) {
      console.error('Error updating document: ', err)
    }
  }

  return (
    <div
      className="flex justify-between items-center"
    >
      <button
        className="px-2 h-8 bg-black/50 hover:bg-black/80 rounded"
        onClick={() => {
          if(isPaidUser) {
            return onHideFooter()
          }
          setShowUpgradeModal({show: true, heading:'Hide heading & footer' })
        }}
      >
        Hide
      </button>
      {!disabled ? (
        <div className="flex space-x-2">
          <div className='hidden sm:flex space-x-2'>
            {layoutButtons.map((button, i) => {
              if (i > 5) return null
              return (
                <ChangeLayoutButton
                  key={button.id}
                  button={button}
                  setScreen={onChangeLayout}
                  selected={screen.layout === button.id}
                  disabled={disabled}
                />
              )
            })}
          </div>

          <MoreLayouts
            screen={screen}
            layouts={layoutButtons}
            setScreen={onChangeLayout}
            disabled={disabled}
            onEditCustom={()=>setShowCustomLayoutModal(true)}
          />
          <ModalCustomLayout
            show={showCustomLayoutModal}
            screen={screen}
            loading={savingCustomLayout}
            onClose={() => setShowCustomLayoutModal(false)}
            onAccept={(customLayout) => saveCustomLayout(customLayout)}
          />
        </div>
      ) : ''}
      <div className="flex items-center space-x-3">
        {user && user.uid === screen.uid &&
         <>
           <button
             className='h-8 px-3 rounded bg-black/50 hover:bg-black/80'
             onClick={() => {
               if(isPaidUser) {
                 return setShowShareDashboardModal(true)
               }
               setShowUpgradeModal({show: true, heading:'Advanced sharing options' })
             }}
           >
            Share
           </button>
           <button
             className='h-8 px-3 rounded bg-black/50 hover:bg-black/80'
             onClick={() => {
               if(isPaidUser) {
                 return setShowCustomizeDashboardModal(true)
               }
               setShowUpgradeModal({show: true, heading:'Customize dashboard' })
             }}
           >
            Customize
           </button>
         </>
        }

        <ModalShareDashboard
          show={showShareDashboardModal}
          screen={screen}
          onClose={() => setShowShareDashboardModal(false)}
          onUpdate={(setting, update)=>saveShareSetting(setting, update)}
          onAccept={() => setShowShareDashboardModal(false)}
        />

        <ModalCustomizeDashboard
          show={showCustomizeDashboardModal}
          screen={screen}
          onClose={() => setShowCustomizeDashboardModal(false)}
          onAccept={(settings) => saveCustomizationSetting(settings)}
          settings={screen.settings}
        />

        {!screen.uid && user ? (
          <button
            className="text-sm text-gray-500 hover:text-gray-300 underline"
            onClick={claimSpace}
          >
            Claim dashboard
          </button>
        ) : ''}
        {user && user.uid === screen.uid ? <ProfileButton /> : <ProfileButton />}
        {isPaidUser &&
        <span className='p-1 text-white bg-gradient-to-r from-indigo-500 to-indigo-700 hover:from-indigo-600 hover:to-indigo-800 rounded'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5">
            <path strokeLinecap="round" strokeLinejoin="round" d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
          </svg>
        </span>}
      </div>
      <ModalUpgrade
        show={showUpgradeModal.show}
        heading={showUpgradeModal.heading}
        onClose={() => setShowUpgradeModal({show: false, heading: showUpgradeModal.heading})}
      />
    </div>
  )
}

import { doc, updateDoc } from 'firebase/firestore'
import { useParams } from 'react-router-dom'

import { db } from '../firebase'

import Layout1 from './layouts/Layout1'
import Layout2 from './layouts/Layout2'
import Layout3 from './layouts/Layout3'
import Layout4 from './layouts/Layout4'
import Layout5 from './layouts/Layout5'
import Layout6 from './layouts/Layout6'
import Layout7 from './layouts/Layout7'
import Layout8 from './layouts/Layout8'
import Layout9 from './layouts/Layout9'
import Layout10 from './layouts/Layout10'
import Layout11 from './layouts/Layout11'
import Layout12 from './layouts/Layout12'
import Layout13 from './layouts/Layout13'
import Layout14 from './layouts/Layout14'
import Layout15 from './layouts/Layout15'
import Layout16 from './layouts/Layout16'
import Layout17 from './layouts/Layout17'
import Layout18 from './layouts/Layout18'
import Layout19 from './layouts/Layout19'
import Layout20 from './layouts/Layout20'
import Layout21 from './layouts/Layout21'
import Layout22 from './layouts/Layout22'
import Layout23 from './layouts/Layout23'
import Layout24 from './layouts/Layout24'
import Layout25 from './layouts/Layout25'
import Layout26 from './layouts/Layout26'
import Layout27 from './layouts/Layout27'
import Layout28 from './layouts/Layout28'
import Layout29 from './layouts/Layout29'
import Layout30 from './layouts/Layout30'
import Layout31 from './layouts/Layout31'
import Layout32 from './layouts/Layout32'
import Layout33 from './layouts/Layout33'
import Layout34 from './layouts/Layout34'
import LayoutCustom from './layouts/LayoutCustom'
import { useMemo } from 'react'

const layoutMap = {
  'layout1': Layout1,
  'layout2': Layout2,
  'layout3': Layout3,
  'layout4': Layout4,
  'layout5': Layout5,
  'layout6': Layout6,
  'layout7': Layout7,
  'layout8': Layout8,
  'layout9': Layout9,
  'layout10': Layout10,
  'layout11': Layout11,
  'layout12': Layout12,
  'layout13': Layout13,
  'layout14': Layout14,
  'layout15': Layout15,
  'layout16': Layout16,
  'layout17': Layout17,
  'layout18': Layout18,
  'layout19': Layout19,
  'layout20': Layout20,
  'layout21': Layout21,
  'layout22': Layout22,
  'layout23': Layout23,
  'layout24': Layout24,
  'layout25': Layout25,
  'layout26': Layout26,
  'layout27': Layout27,
  'layout28': Layout28,
  'layout29': Layout29,
  'layout30': Layout30,
  'layout31': Layout31,
  'layout32': Layout32,
  'layout33': Layout33,
  'layout34': Layout34,
  'layoutCustom': LayoutCustom,
}

export default function ScreenContent ({ screen, profile = false, disabled = false }) {
  const { screenId } = useParams()
  const { layout, modules, customLayout = [] } = screen

  const handleUpdateModule = async (update, index) => {
    const docRef = doc(db, 'screens', screenId)

    let updatedArray

    if(index + 1 > modules.length) {
      let addedArrayItems = Array.from({length: index + 1 - modules.length}, (_, i) => ({type: 'PLACEHOLDER'}))
      updatedArray = Array.from([...modules, ...addedArrayItems])
    } else {
      updatedArray = Array.from(modules)
    }
    
    
    updatedArray[index] = update

    await updateDoc(docRef, {
      updated: new Date(),
      modules: [...updatedArray],
    })
  }

  const LayoutComponent =  useMemo(() => layoutMap[layout], [layout])

  return LayoutComponent ? (
    <LayoutComponent
      modules={modules}
      customLayout={customLayout}
      onUpdate={handleUpdateModule}
      profile={profile}
      disabled={disabled}
      settings={screen.settings}
    />
  ) : (
    <main className="flex items-center justify-center h-full text-white">
      <div className="text-xl font-semibold">Loading...</div>
    </main>
  )
}

import ModuleWrapper from '../modules/ModuleWrapper.js'
import LayoutBase from './LayoutBase'

export default function Layout15({ modules, onUpdate, ...props }) {
  return (
    <LayoutBase cols={3} rows={2} {...props}>
      <div className="col-span-1 row-span-1">
        <ModuleWrapper index={0} module={modules[0]} onUpdate={onUpdate} {...props} />
      </div>
      <div className="col-span-1 row-span-1">
        <ModuleWrapper index={1} module={modules[1]} onUpdate={onUpdate} {...props} />
      </div>
      <div className="col-span-1 row-span-1">
        <ModuleWrapper index={2} module={modules[2]} onUpdate={onUpdate} {...props} />
      </div>
      <div className="col-span-1 row-span-1">
        <ModuleWrapper index={3} module={modules[3]} onUpdate={onUpdate} {...props} />
      </div>
      <div className="col-span-1 row-span-1">
        <ModuleWrapper index={4} module={modules[4]} onUpdate={onUpdate} {...props} />
      </div>
      <div className="col-span-1 row-span-1">
        <ModuleWrapper index={5} module={modules[5]} onUpdate={onUpdate} {...props} />
      </div>
    </LayoutBase>
  )
}

import { useEffect, useState } from 'react'
import { useParams  } from 'react-router-dom'
import { doc, onSnapshot } from 'firebase/firestore'
import { db } from '../firebase'
import { Helmet } from 'react-helmet'

export default function Device () {
  let { deviceId } = useParams()
  const [device, setDevice] = useState({})
  const [identified, setIdentified] = useState(false)

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    let unsub

    if (!deviceId) {
      console.info('Missing device ID')
    } else {
      unsub = onSnapshot(doc(db, 'devices', deviceId), (doc) => {
        const d = new Date()
        if(doc.data().identifiedAt?.seconds * 1000 - d.getTime() > -3000) {
          setIdentified(true)

          setTimeout(()=>{
            setIdentified(false)
          }, [3000])
        }
        setDevice(doc.data())
      })
    }

    return unsub
  }, [deviceId])
  /* eslint-enable react-hooks/exhaustive-deps */

  if (!deviceId) {
    return (
      <main className="flex items-center justify-center h-screen text-white">
        <Helmet>
          <title>Device {deviceId}</title>
        </Helmet>
        <div className="text-xl font-semibold">No dashboard selected...</div>
      </main>
    )
  }

  if (!device.screenId) {
    return (
      <main className="flex items-center justify-center h-screen text-white">
        <Helmet>
          <title>Loading Device ...</title>
        </Helmet>
        <div className="text-xl font-semibold">Loading...</div>
      </main>
    )
  }

  return (
    <div
      className="relative h-screen flex flex-col justify-between overflow-hidden"
    >
      <Helmet>
        <title>Device {deviceId}</title>
      </Helmet>
      {identified && <Identified />}
      <section className="flex-1 max-h-screen min-h-0">
        {/* {screenContentMemo} */}
        <iframe
          src={`${window.location.origin}/s/${device.screenId}?hideHeaderFooter=1`}
          className='h-screen w-screen'
          title='Dashmaster device'
        />
      </section>


    </div>
  )
}


const Identified = () => {
  let { deviceId } = useParams()
  return (
    <span className='absolute top-0 left-0 w-screen h-screen bg-gray-900/90 text-white flex flex-col space-y-3 items-center justify-center'>
      <span className='mb-6 text-2xl font-semibold'>Look over here 👋</span>
      <span className='uppercase font-semibold'>Device ID</span>
      <span className='text-4xl'>{deviceId}</span>
    </span>
  )
}

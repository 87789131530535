import BrandingColours from '../../consts/branding'
import getContrastingColor from '../../utils/getContrastingColor'


export default function ModuleTypePlaceholder ({ onEdit, disabled = false, settings }) {
  const textColor = getContrastingColor(settings?.secondaryColour ? settings?.secondaryColour : BrandingColours.Secondary)
  return (
    <button
      style={{
        backgroundColor: settings?.secondaryColour ? settings?.secondaryColour : BrandingColours.Secondary,
        color: textColor,
      }}
      className={`
        block h-full w-full cursor-pointer rounded-md border-4 border-dashed border-current transition-colors
        ${disabled ? 'cursor-default' : 'hover:brightness-110'}
      `}
      disabled={disabled}
      onClick={onEdit}
    >
      {disabled ? '' : (
        <span className="text-xl font-semibold">
          Add
        </span>
      )}
    </button>
  )
}

import ModuleEditorTiles from './ModuleEditorTiles.js'
import ModalColorPicker from '../modals/ModalColorPicker.js'
import { types, components, tiles } from './moduleTypes.js'
import { useState } from 'react'

export default function ModuleEditor ({ module = undefined, onUpdate, onCancel, settings }) {
  const [moduleType, setModuleType] = useState(module?.type)
  const [modulePayload, setModulePayload] = useState(module?.payload)
  const [colorPickerOpen, setColorPickerOpen] = useState(false)
  const ModuleEditor = components[moduleType]?.ModuleEditor
  const tile = tiles[moduleType]

  function onSave() {
    onUpdate({
      ...module,
      type: moduleType,
      payload: modulePayload || {},
    })
  }

  return (
    <section
      id="module-editor"
      className="pb-16"
    >
      {ModuleEditor ? (
        <div>
          <div className="flex items-center gap-2 text-gray-400 mb-4">
            <h2 className="text-xs uppercase font-semibold">
              Styling
            </h2>
            <span className="flex-1 h-px bg-gray-300" />
          </div>
          <div className="flex gap-4">
            <input
              type="text"
              name="module-custom-name-label"
              id="module-custom-name-label"
              className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded"
              placeholder='My label'
              value={modulePayload?.label || ''}
              onChange={(e) => setModulePayload({...modulePayload, label: e.target.value})}
            />
            <button
              className="group pl-2 h-8 bg-white text-gray-400 hover:bg-gray-300 hover:text-gray-800 shadow-sm rounded flex items-center"
              onClick={() => setColorPickerOpen(!colorPickerOpen)}
            >
              <span className="text-xs font-medium">
                Background
              </span>
              <div
                className="w-8 h-8 rounded-r ml-2 border-l"
                style={{ backgroundColor: modulePayload?.bgColor || settings?.primaryColour || '#1f2937' }}
              >
                <span className="sr-only">Choose color</span>
              </div>
            </button>
          </div>

          <div className="mt-4 flex items-center gap-2 text-gray-400 mb-4">
            <h2 className="text-xs uppercase font-semibold">
              {tile.label}
            </h2>
            <span className="flex-1 h-px bg-gray-300" />
          </div>
          <ModuleEditor payload={modulePayload} onUpdatePayload={setModulePayload} />
          <ModalColorPicker
            show={colorPickerOpen}
            hexCode={modulePayload?.bgColor || settings?.primaryColour || '#1f2937'}
            onClose={() => setColorPickerOpen(false)}
            onAccept={(hex) => setModulePayload({...modulePayload, bgColor: hex})}
          />
        </div>
      ) : (
        <ModuleEditorTiles onSelect={setModuleType} />
      )}

      <footer className="absolute left-6 right-6 bottom-4 flex justify-end items-center space-x-3">
        {ModuleEditor ? (
          <button
            type="button"
            className="px-4 h-8 text-xs font-medium rounded shadow-sm text-gray-900 bg-gray-200 hover:bg-gray-300 transition-colors"
            onClick={() => {
              setModuleType(types.PLACEHOLDER)
              setModulePayload({})
            }}
          >
            <span className="flex items-center space-x-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                className="inline w-5 h-5"
              >
                <path
                  fillRule="evenodd"
                  d="M18 10a.75.75 0 01-.75.75H4.66l2.1 1.95a.75.75 0 11-1.02 1.1l-3.5-3.25a.75.75 0 010-1.1l3.5-3.25a.75.75 0 111.02 1.1l-2.1 1.95h12.59A.75.75 0 0118 10z"
                  clipRule="evenodd"
                />
              </svg>
              <span>Switch Module</span>
            </span>
          </button>
        ) : (
          <span className="text-xs">
            Missing something?&nbsp;
            <a className='underline' href="mailto:team@dashmaster2k.com?subject=Dashmaster 2k Module request">Request a Module</a>
          </span>
        )}
        <div className="flex-grow" />
        {module?.type !== 'PLACEHOLDER' ? (
          <button
            type="button"
            className="px-4 h-8 text-xs font-medium rounded shadow-sm text-gray-900 bg-gray-200 hover:bg-gray-300 transition-colors"
            onClick={()=> onUpdate({
              ...module,
              type: 'PLACEHOLDER',
            })}
          >
            Clear
          </button>
        ) : ''}
        <button
          type="button"
          className="px-4 h-8 text-xs font-medium rounded shadow-sm text-gray-900 bg-gray-200 hover:bg-gray-300 transition-colors"
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          type="button"
          className="px-4 h-8 text-xs font-medium rounded shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 transition-colors"
          onClick={onSave}
        >
          Save
        </button>
      </footer>
    </section>
  )
}

import ModalWrapper from './ModalWrapper.js'
import { useState, useEffect } from 'react'
import BrandingColours from '../../consts/branding.js'
import ModalColorPicker from './ModalColorPicker.js'

const _defaultSettings = { primaryColour: BrandingColours.Primary, secondaryColour: BrandingColours.Secondary, logo: '' }

export default function ModalCustomizeDashboard ({
  show,
  heading = 'Customize Dashboard',
  closable = true,
  onClose,
  onAccept,
  settings = _defaultSettings,
}) {
  const [internalSettings, setInternalSettings] = useState(_defaultSettings)
  const [colorPickerOpen, setColorPickerOpen] = useState(false)
  const [colourPickerChoice, setColourPickerChoice] = useState(null)

  function onWrapperAccept () {
    onAccept(internalSettings)
    onClose()
  }

  useEffect(()=>{
    setInternalSettings(settings)
  }, [settings])

  return (
    <ModalWrapper
      show={show}
      heading={heading}
      closeable={closable}
      onClose={onClose}
      onAccept={onWrapperAccept}
      width="500"
    >
      <div>
        <p className='font-semibold mb-2'>Colors</p>
        <div className='flex gap-3'>
          <button
            className="group pl-2 h-8 bg-white text-gray-700 hover:bg-gray-300 hover:text-gray-800 shadow-sm rounded flex items-center"
            onClick={() => {
              setColorPickerOpen(true)
              setColourPickerChoice('primaryColour')
            }}
          >
            <span className="text-xs font-medium">
              Primary
            </span>
            <div
              className="w-8 h-8 rounded-r ml-2 border-l"
              style={{ backgroundColor: internalSettings.primaryColour }}
            >
              <span className="sr-only">Choose color</span>
            </div>
          </button>
          <button
            className="group pl-2 h-8 bg-white text-gray-700 hover:bg-gray-300 hover:text-gray-800 shadow-sm rounded flex items-center"
            onClick={() => {
              setColorPickerOpen(true)
              setColourPickerChoice('secondaryColour')
            }}
          >
            <span className="text-xs font-medium">
              Secondary
            </span>
            <div
              className="w-8 h-8 rounded-r ml-2 border-l"
              style={{ backgroundColor: internalSettings.secondaryColour }}
            >
              <span className="sr-only">Choose color</span>
            </div>
          </button>
        </div>
        <ModalColorPicker
          show={colorPickerOpen}
          hexCode={internalSettings[colourPickerChoice]}
          onClose={() => setColorPickerOpen(false)}
          onAccept={(hex) => setInternalSettings({...internalSettings, [colourPickerChoice]: hex})}
        />
      </div>
      <div>
        <p className='font-semibold mb-2'>Logo</p>
        <div className='flex flex-col gap-1'>
          <label htmlFor="image-url" className="block text-sm font-medium text-gray-700">
            Image URL
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="image-url"
              id="image-url"
              className="px-2 h-8 shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
              placeholder="Paste URL..."
              value={internalSettings.logo}
              onChange={(e) => setInternalSettings({...internalSettings, logo: e.target.value})}
            />
          </div>
        </div>
      </div>
    </ModalWrapper>
  )
}

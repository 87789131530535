export default function ChangeLayoutButton({ button, setScreen, selected, disabled }) {
  return (
    <button
      className={
        `h-8 w-8 flex items-center justify-center rounded transition-colors
        ${selected ? 'bg-black/80 hover:bg-black/80' : 'bg-black/50 hover:bg-black/80'}
        ${disabled ? 'pointer-events-none' : ''}
     `}
      onClick={() => setScreen(button.id)}
      disabled={disabled}
    >
      <svg
        className={[
          'w-6 h-4 inline',
          selected ? 'text-white/80' : 'text-white/40',
        ].join(' ')}
        viewBox="0 0 30 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        {button.svg}
      </svg>
    </button>
  )
}

import { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { getAuthUser } from '../../firebase.js'
import UserIcon from './UserIcon.js'

export default function ProfileButton ({ children, btnClass = 'h-8 px-3 rounded bg-black/50 hover:bg-black/80' }) {
  const [user, setUser] = useState(null)

  useEffect(() => { loadUser() })

  async function loadUser () {
    const user = await getAuthUser()
    setUser(user)
  }

  return user ? (
    <Link
      to="/dashboards"
      className={'flex items-center transition-colors ' + btnClass}
    >
      <UserIcon user={user} />
      <div className="ml-2">My Dashboards</div>
    </Link>
  ) : children || (
    <Link className={'flex items-center transition-colors ' + btnClass} to="/login">Login</Link>
  )
}

import ModuleTypePlaceholder from './ModulePlaceholder.js'
import { components } from './moduleTypes.js'
import React from 'react'
import getContrastingColor from '../../utils/getContrastingColor.js'

export default function ModuleViewer ({ module = undefined, onToggleEdit, onUpdate, disabled = false, settings }) {
  const ModuleViewer =  React.useMemo(() => components[module?.type]?.ModuleViewer, [module?.type])

  const bgColor = module?.payload?.bgColor || settings?.primaryColour || '#1f2937'
  const textColor = getContrastingColor(bgColor)

  return (
    <div
      className="h-full"
      style={{
        backgroundColor: bgColor,
        color: textColor,
      }}
    >
      {module?.payload?.label && (
        <div className='absolute z-10 bottom-4 left-1/2 -translate-x-1/2 inline-block pointer-events-none'>
          <span className='px-2 py-1 bg-black/80 text-white rounded-sm select-none'>{module?.payload?.label || ''}</span>
        </div>
      )}
      {ModuleViewer ? (
        <ModuleViewer
          payload={module?.payload}
          onUpdatePayload={payload => onUpdate({ ...module, payload })}
          settings={settings}
        />
      ) : (
        <ModuleTypePlaceholder onEdit={onToggleEdit} disabled={disabled} settings={settings} />
      )}
    </div>
  )
}

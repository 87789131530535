export function ModuleViewer({ payload }) {
  const _url = new URL('https://h2r.graphics/time?hideControls=true')

  _url.searchParams.set('layout', payload?.clock_type || 'LARGE_CLOCK')
  _url.searchParams.set('colour', payload?.clock_color || '')
  _url.searchParams.set('24hour', payload?.clock_display_format === '12_hour' ? false : true)

  let offset = { plusMinus: '+', hours: 0, minutes: 0 }
  if(payload?.clock_offset_plus_minus) offset.plusMinus = payload?.clock_offset_plus_minus
  if(payload?.clock_offset_hours) offset.hours = payload?.clock_offset_hours
  if(payload?.clock_offset_minutes) offset.minutes = payload?.clock_offset_minutes
  _url.searchParams.set('offset', `${offset.plusMinus}${offset.hours}h${offset.minutes}m`)

  return (
    <iframe
      title="Frame"
      width="100%"
      height="100%"
      src={_url.href}
      frameBorder="0"
      allowFullScreen
      allow="screen-wake-lock; autoplay"
    />
  )
}

export function ModuleEditor({ payload, onUpdatePayload }) {
  function onUpdate(key, value) {
    const newPayload = { ...payload, [key]: value }
    onUpdatePayload(newPayload)
  }

  return (
    <div className="space-y-3">
      <div>
        <label htmlFor="clock_type" className="block text-sm font-medium text-gray-700">
          Type
        </label>
        <select
          id="clock_type"
          name="clock_type"
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={payload?.clock_type || 'LARGE_CLOCK'}
          onChange={(e) => onUpdate('clock_type', e.target.value)}
        >
          <option value="LARGE_CLOCK">Circle clock</option>
          <option value="TIME_ONLY">Large text</option>
        </select>
      </div>
      <div>
        <label htmlFor="clock_color" className="block text-sm font-medium text-gray-700">
          Color
        </label>
        <select
          id="clock_color"
          name="clock_color"
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={payload?.clock_color || ''}
          onChange={(e) => onUpdate('clock_color', e.target.value)}
        >
          <option value="">White</option>
          <option value="RED">Red</option>
          <option value="GREEN">Green</option>
        </select>
      </div>
      <div>
        <label htmlFor="clock_display_format" className="block text-sm font-medium text-gray-700">
          Display format
        </label>
        <select
          id="clock_display_format"
          name="clock_display_format"
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={payload?.clock_display_format || '24_hour'}
          onChange={(e) => onUpdate('clock_display_format', e.target.value)}
        >
          <option value="24_hour">24 Hour</option>
          <option value="AM_PM">AM/PM</option>
        </select>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Offset
        </label>
        <div className="flex items-end gap-1">
          <select
            className="mt-1 w-14 pl-2 pr-2 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            value={payload?.clock_offset_plus_minus || '+'}
            onChange={(e) => onUpdate('clock_offset_plus_minus', e.target.value)}
          >
            <option value="+">+</option>
            <option value="-">-</option>
          </select>
          <input
            type="number"
            className="mt-1 block w-14 pl-2 pr-2 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            value={payload?.clock_offset_hours || 0}
            onChange={(e) => onUpdate('clock_offset_hours', e.target.value)}
            min={0}
            max={23}
          />
          <span className="pr-1">hr</span>
          <input
            type="number"
            className="mt-1 block w-14 pl-2 pr-2 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
            value={payload?.clock_offset_minutes || 0}
            onChange={(e) => onUpdate('clock_offset_minutes', e.target.value)}
            min={0}
            max={59}
          />
          <span className="pr-1">min</span>
        </div>
      </div>
    </div>
  )
}

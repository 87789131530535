import * as ModuleTypeIFrame from './ModuleTypeIFrame.js'
import * as ModuleTypeImage from './ModuleTypeImage.js'
import * as ModuleTypeText from './ModuleTypeText.js'
import * as ModuleTypeTextAndImage from './ModuleTypeTextAndImage.js'
import * as ModuleTypeButtons from './ModuleTypeButtons.js'
import * as ModuleTypeYoutube from './ModuleTypeYoutube.js'
import * as ModuleTypeYouTubeLiveChat from './ModuleTypeYouTubeLiveChat.js'
import * as ModuleTypeVimeo from './ModuleTypeVimeo.js'
import * as ModuleTypeSlido from './ModuleTypeSlido.js'
import * as ModuleTypeMentimeter from './ModuleTypeMentimeter.js'
import * as ModuleTypeH2RGraphics from './ModuleTypeH2RGraphics.js'
import * as ModuleTypeStagetimer from './ModuleTypeStagetimer.js'
import * as ModuleTypeCompanion from './ModuleTypeCompanion.js'
import * as ModuleTypeVanitySatelliteMap from './ModuleTypeVanitySatelliteMap.js'
import * as ModuleTypeVanityLineChart from './ModuleTypeVanityLineChart.js'
import * as ModuleTypeHLS from './ModuleTypeHLS.js'
import * as ModuleTypeTime from './ModuleTypeTime.js'
import * as ModuleTwitch from './ModuleTypeTwitch.js'
import * as ModuleTypeQRCode from './ModuleTypeQRCode.js'
import * as ModuleTypeWelcome from './ModuleTypeWelcome.js'
import * as ModuleTypeFacebook from './ModuleTypeFacebook.js'
import * as ModuleTypeGoogleDocs from './ModuleTypeGoogleDocs.js'
import * as ModuleTypeGoogleSheets from './ModuleTypeGoogleSheets.js'
import * as ModuleTypeWebcam from './ModuleTypeWebcam.js'
import * as ModuleTypeVdoNinja from './ModuleTypeVdoNinja.js'
import * as ModuleTypeTwitter from './ModuleTypeTwitter.js'
import * as ModuleTypeZoomMeeting from './ModuleTypeZoomMeeting.js'
import * as ModuleTypeExternalLinks from './ModuleTypeExternalLinks.js'
import * as ModuleTypeChat from './ModuleTypeChat.js'
import * as ModuleTypeScreenshot from './ModuleTypeScreenshot.js'
import * as ModuleTypeTestVideosAndPatterns from './ModuleTypeTestVideosAndPatterns.js'
import * as ModuleTypeRundownStudio from './ModuleTypeRundownStudio.js'
import * as ModuleTypeProductionClock from './ModuleTypeProductionClock.js'

/**
 * List of Integrations
 * Make sure the module name follows the follwing naming convention:
 * PLACEHOLDER => ModuleTypePlaceholder.js
 * SNAKE_CASE => ModuleTypeSnakeCase.js
 */
export const PLACEHOLDER = 'PLACEHOLDER'
export const EMBED = 'EMBED'
export const IFRAME = 'IFRAME'
export const IMAGE = 'IMAGE'
export const TEXT = 'TEXT'
export const TEXT_AND_IMAGE = 'TEXT_AND_IMAGE'
export const BUTTONS = 'BUTTONS'
export const YOUTUBE = 'YOUTUBE'
export const YOUTUBE_LIVE_CHAT = 'YOUTUBE_LIVE_CHAT'
export const VIMEO = 'VIMEO'
export const SLIDO = 'SLIDO'
export const MENTIMETER = 'MENTIMETER'
export const H2RGRAPHICS = 'H2RGRAPHICS'
export const STAGETIMER = 'STAGETIMER'
export const COMPANION = 'COMPANION'
export const VANITY_SATELLITE_MAP = 'VANITY_SATELLITE_MAP'
export const VANITY_LINE_CHART = 'VANITY_LINE_CHART'
export const TIME = 'TIME'
export const TWITCH = 'TWITCH'
export const HLS = 'HLS'
export const QRCODE = 'QRCODE'
export const WELCOME = 'WELCOME'
export const FACEBOOK = 'FACEBOOK'
export const GOOGLE_DOCS = 'GOOGLE_DOCS'
export const GOOGLE_SHEETS = 'GOOGLE_SHEETS'
export const WEBCAM = 'WEBCAM'
export const VDO_NINJA = 'VDO_NINJA'
export const ZOOM_MEETING = 'ZOOM_MEETING'
export const TWITTER = 'TWITTER'
export const EXTERNAL_LINKS = 'EXTERNAL_LINKS'
export const CHAT = 'CHAT'
export const SCREENSHOT = 'SCREENSHOT'
export const VIDEOS_AND_PATTERNS = 'VIDEOS_AND_PATTERNS'
export const RUNDOWN_STUDIO = 'RUNDOWN_STUDIO'
export const PRODUCTION_CLOCK = 'PRODUCTION_CLOCK'

export const components = {
  IFRAME: ModuleTypeIFrame,
  IMAGE: ModuleTypeImage,
  TEXT: ModuleTypeText,
  TEXT_AND_IMAGE: ModuleTypeTextAndImage,
  BUTTONS: ModuleTypeButtons,
  YOUTUBE: ModuleTypeYoutube,
  YOUTUBE_LIVE_CHAT: ModuleTypeYouTubeLiveChat,
  VIMEO: ModuleTypeVimeo,
  SLIDO: ModuleTypeSlido,
  MENTIMETER: ModuleTypeMentimeter,
  H2RGRAPHICS: ModuleTypeH2RGraphics,
  STAGETIMER: ModuleTypeStagetimer,
  COMPANION: ModuleTypeCompanion,
  VANITY_SATELLITE_MAP: ModuleTypeVanitySatelliteMap,
  VANITY_LINE_CHART: ModuleTypeVanityLineChart,
  HLS: ModuleTypeHLS,
  TIME: ModuleTypeTime,
  TWITCH: ModuleTwitch,
  QRCODE: ModuleTypeQRCode,
  WELCOME: ModuleTypeWelcome,
  FACEBOOK: ModuleTypeFacebook,
  GOOGLE_DOCS: ModuleTypeGoogleDocs,
  GOOGLE_SHEETS: ModuleTypeGoogleSheets,
  WEBCAM: ModuleTypeWebcam,
  VDO_NINJA: ModuleTypeVdoNinja,
  ZOOM_MEETING: ModuleTypeZoomMeeting,
  TWITTER: ModuleTypeTwitter,
  EXTERNAL_LINKS: ModuleTypeExternalLinks,
  CHAT: ModuleTypeChat,
  SCREENSHOT: ModuleTypeScreenshot,
  VIDEOS_AND_PATTERNS: ModuleTypeTestVideosAndPatterns,
  RUNDOWN_STUDIO: ModuleTypeRundownStudio,
  PRODUCTION_CLOCK: ModuleTypeProductionClock,
}

export const categories = [
  {
    heading: 'Native Modules',
    modules: [
      IFRAME,
      IMAGE,
      TEXT,
      TEXT_AND_IMAGE,
      BUTTONS,
      EXTERNAL_LINKS,
      QRCODE,
      TIME,
      WEBCAM,
      HLS,
      CHAT,
      SCREENSHOT,
     
    ],
  },
  {
    heading: 'Third-Party Modules',
    modules: [
      YOUTUBE,
      YOUTUBE_LIVE_CHAT,
      VIMEO,
      SLIDO,
      MENTIMETER,
      H2RGRAPHICS,
      STAGETIMER,
      COMPANION,
      TWITCH,
      FACEBOOK,
      GOOGLE_DOCS,
      GOOGLE_SHEETS,
      VDO_NINJA,
      ZOOM_MEETING,
      TWITTER,
      RUNDOWN_STUDIO,
      PRODUCTION_CLOCK,
    ],
  },
  {
    heading: 'Vanity Modules',
    modules: [
      VANITY_SATELLITE_MAP,
      VANITY_LINE_CHART,
      VIDEOS_AND_PATTERNS,
    ],
  },
]

/**
 * Options for the tile selector
 */
export const tiles = {
  [PLACEHOLDER]: {
    label: 'Placeholder',
    color: '#222',
    icon: '/img/integrations/icon-placeholder.svg',
    hide: true,
  },
  [WELCOME]: {
    label: 'Welcome',
    color: '#222',
    icon: '/img/integrations/icon-placeholder.svg',
    hide: true,
  },
  [IFRAME]: {
    label: 'iFrame',
    color: '#ffffff',
    icon: '/img/integrations/icon-iframe.svg',
  },
  [IMAGE]: {
    label: 'Image',
    color: '#ffffff',
    icon: '/img/integrations/icon-image.svg',
    hide: true,
  },
  [TEXT]: {
    label: 'Text',
    color: '#ffffff',
    icon: '/img/integrations/icon-text.svg',
    hide: true,
  },
  [TEXT_AND_IMAGE]: {
    label: 'Text/Image',
    color: '#ffffff',
    icon: '/img/integrations/icon-text.svg',
  },
  [BUTTONS]: {
    label: 'Buttons',
    color: '#ffffff',
    icon: '/img/integrations/icon-pointer.svg',
  },
  [TIME]: {
    label: 'Time of day',
    color: '#ffffff',
    icon: '/img/integrations/icon-time.svg',
  },
  [WEBCAM]: {
    label: 'Webcam',
    color: '#ffffff',
    icon: '/img/integrations/icon-webcam.svg',
  },
  [CHAT]: {
    label: 'Chat',
    color: '#ffffff',
    icon: '/img/integrations/icon-chat.svg',
    paid: true,
  },
  [SCREENSHOT]: {
    label: 'Screenshot',
    color: '#ffffff',
    icon: '/img/integrations/icon-camera.svg',
    paid: true,
  },
  
  [YOUTUBE]: {
    label: 'YouTube',
    color: '#ff0000',
    icon: '/img/integrations/icon-youtube.svg',
  },
  [YOUTUBE_LIVE_CHAT]: {
    label: 'YouTube Live Chat',
    color: '#ff0000',
    icon: '/img/integrations/icon-youtube.svg',
    hide: true,
  },
  [VIMEO]: {
    label: 'Vimeo',
    color: '#00adef',
    icon: '/img/integrations/icon-vimeo.svg',
  },
  [TWITCH]: {
    label: 'Twitch',
    color: '#6441A5',
    icon: '/img/integrations/icon-twitch.svg',
  },
  [FACEBOOK]: {
    label: 'Facebook',
    color: '#4267B2',
    icon: '/img/integrations/icon-facebook.svg',
  },
  [HLS]: {
    label: 'HLS',
    color: '#fff',
    icon: '/img/integrations/icon-hls.svg',
  },
  [EXTERNAL_LINKS]: {
    label: 'Links',
    color: '#fff',
    icon: '/img/integrations/icon-external-links.svg',
  },
  [SLIDO]: {
    label: 'Slido',
    color: '#39ac37',
    icon: '/img/integrations/icon-slido.svg',
  },
  [MENTIMETER]: {
    label: 'Mentimeter',
    color: '#fff',
    icon: '/img/integrations/icon-mentimeter.svg',
  },
  [H2RGRAPHICS]: {
    label: 'H2R Graphics',
    color: '#333333',
    icon: '/img/integrations/icon-h2rgraphics.svg',
  },
  [STAGETIMER]: {
    label: 'Stagetimer.io',
    color: '#171717',
    icon: '/img/integrations/icon-stagetimer.svg',
  },
  [COMPANION]: {
    label: 'Companion',
    color: '#d60515',
    icon: '/img/integrations/icon-companion.png',
  },
  [QRCODE]: {
    label: 'QR Code',
    color: '#ffffff',
    icon: '/img/integrations/icon-qr-code.svg',
  },
  [GOOGLE_DOCS]: {
    label: 'Google Docs',
    color: '#ffffff',
    icon: '/img/integrations/icon-google-docs.svg',
  },
  [GOOGLE_SHEETS]: {
    label: 'Google Sheets',
    color: '#ffffff',
    icon: '/img/integrations/icon-google-sheets.svg',
  },
  [VDO_NINJA]: {
    label: 'VDO.ninja',
    color: '#0f131d',
    icon: '/img/integrations/icon-vdo-ninja.png',
  },
  [ZOOM_MEETING]: {
    label: 'Zoom meeting',
    color: '#2d8cff',
    icon: '/img/integrations/icon-zoom-meeting.svg',
    hide: true,
  },
  [VANITY_SATELLITE_MAP]: {
    label: 'Satellite Map',
    color: '#002D47',
    icon: '/img/integrations/icon-chart-simple.svg',
    thumb: '/img/integrations/thumb-satellite-map.jpg',
  },
  [VANITY_LINE_CHART]: {
    label: 'Line Chart',
    color: '#002D47',
    icon: '/img/integrations/icon-chart-simple.svg',
    thumb: '/img/integrations/thumb-line-chart.jpg',
  },
  [VIDEOS_AND_PATTERNS]: {
    label: 'Test patterns',
    color: '#222',
    icon: '/img/integrations/icon-chart-simple.svg',
    thumb: '/img/integrations/icon-test-patterns.png',
  },
  [TWITTER]: {
    label: 'Twitter/X',
    color: '#1aa1f1',
    icon: '/img/integrations/icon-twitter.svg',
  },
  [RUNDOWN_STUDIO]: {
    label: 'Rundown Studio',
    color: '#000',
    icon: '/img/integrations/icon-rundown-studio.svg',
  },
  [PRODUCTION_CLOCK]: {
    label: 'Production Clock',
    color: '#fff',
    thumb: '/img/integrations/icon-production-clock.png',
    icon: '/img/integrations/icon-time.svg',
    hideIcon: true,
  },

}

export function getModuleName(type = '') {
  const lc = type.toLowerCase()
  return 'ModuleType' + lc.charAt(0).toUpperCase() + lc.slice(1) + '.js'
}

export function isPlaceholder(type = '') {
  return !Object.keys(components).includes(type)
}

export const types = {
  PLACEHOLDER,
  WELCOME,
  IFRAME,
  IMAGE,
  TEXT,
  BUTTONS,
  YOUTUBE,
  VIMEO,
  SLIDO,
  H2RGRAPHICS,
  STAGETIMER,
  COMPANION,
  VANITY_LINE_CHART,
  TWITCH,
  QRCODE,
  FACEBOOK,
  PRODUCTION_CLOCK,
}

export default types

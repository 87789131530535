import React, { useEffect, useState } from 'react'
import ModalWrapper from './ModalWrapper'

export default function ModalShareDashboard ({
  show,
  heading = 'Share',
  screen,
  body='Lock down or open up your dashboard.',
  onUpdate,
  closable,
  onClose,
  onAccept,
}) {
  const [emails, setEmails] = useState([])
  const [emailInput, setEmailInput] = useState('')
  const [url, setUrl] = useState(window.location.href)
  const [urlParams, setUrlParams] = useState([])

  const addEmailAddress = () => {
    const regex = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/
    const match = regex.test(emailInput)
    if(!match) return null
    setEmails([...emails, emailInput])

    setEmailInput('')
    return updateEmails([...emails, emailInput])
  }

  const removeEmailAddress = (removeIndex) =>{
    const newArray = Array.from(emails)
    newArray.splice(removeIndex, 1)

    setEmails(newArray)
    return updateEmails(newArray)
  }

  const updateEmails = (updatedEmails)=>{
    return onUpdate('emails', updatedEmails)
  }

  const rebuildUrlParams = (key, value) => {
    let array = Array.from(urlParams)

    if (value === true) {
      // Add the key to the array if it doesn't exist already
      if (!array.includes(key)) {
        array.push(key)
      }
    } else if (value === false) {
      // Remove the key from the array if it exists
      array = array.filter(item => item !== key)
    }

    setUrlParams(array)
  }

  useEffect(()=>{
    setEmails([...screen.permissions?.emails || []])
  }, [screen.permissions])

  useEffect(()=>{
    const _url = new URL(window.location.href)
    urlParams.forEach((param)=>{
      _url.searchParams.append(param, '1')
    })
    setUrl(_url)
  }, [urlParams])

  return (
    <ModalWrapper
      show={show}
      heading={heading}
      closeable={closable}
      onClose={onClose}
      acceptButtonText='Done'
      onAccept={onAccept}
    >
      {/* <span>{body}</span> */}
      <div>
        <label htmlFor="share-setting" className="block text-sm font-medium text-gray-700">
     Share setting
        </label>
        <select
          id="share-setting"
          name="share-setting"
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          value={screen.permissions?.setting || 'anyone'}
          onChange={(e) => onUpdate('setting', e.target.value)}
        >
          <option value="anyone">Anyone with the link</option>
          <option value="only-me">Only me</option>
          <option value="only-emails">Specific people</option>
        </select>
      </div>

      {screen.permissions?.setting === 'only-emails' &&  <div className=''>
        <label htmlFor="email-addresses" className="block text-sm font-medium text-gray-700">
          Email addresses
        </label>
        <div className='flex gap-2'>
          <input
            id='email-addresses'
            type='email'
            className='mt-1 block w-full pl-2 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md'
            placeholder='myfriend@email.com'
            value={emailInput}
            onChange={(e)=>setEmailInput(e.target.value)}
            onKeyDown={(e)=>{
              if(e.key === 'Enter') {
                return addEmailAddress()
              } return null
            }}
          />
          <button 
            className='px-2 whitespace-nowrap rounded bg-white h-8 mt-auto text-sm disabled:opacity-50'
            disabled={!emailInput}
            onClick={()=> addEmailAddress()}
          >Add to list</button>
        </div>
        
        {emails.length > 0 && <span className='p-1 my-1 flex space-x-1 text-xs rounded w-full'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-4 h-4">
            <path strokeLinecap="round" strokeLinejoin="round" d="M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z" />
          </svg>
          <span>Users will need a Dashmaster 2k account</span>
        </span>}

        <div className='mt-1 flex flex-wrap'>
          {emails.map((email, index)=>{
            return (
              <div key={index} className='mr-1 mb-1 px-2 py-1 text-sm bg-gray-50 rounded'>
                <span className=''>{email}</span>
                <button className='ml-1' onClick={()=>removeEmailAddress(index)}>✖</button>
              </div>
            )
          })}
        </div>

        
      </div>}

      <div>
        <label htmlFor="share-setting" className="block text-sm font-medium text-gray-700">
          Dashboard URL
        </label>
        <input
          id="share-setting"
          name="share-setting"
          type='string'
          className="mt-1 block w-full pl-2 pr-10 h-8 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md disabled:bg-gray-200"
          value={url}
          disabled={true}
        />
      </div>
      <div className='flex gap-3'>
        <label htmlFor="hide-header">
          <input
            id="hide-header"
            name="hide-header"
            className="w-4 h-3 mr-1"
            type="checkbox"
            checked={urlParams.includes('hideHeader') || false}
            onChange={(e) => rebuildUrlParams('hideHeader', e.target.checked)}
          />
          <span className='text-sm'>Hide header</span>
        </label>
        <label htmlFor="hide-footer">
          <input
            id="hide-footer"
            name="hide-footer"
            className="w-4 h-3 mr-1"
            type="checkbox"
            checked={urlParams.includes('hideFooter') || false}
            onChange={(e) => rebuildUrlParams('hideFooter', e.target.checked)}
          />
          <span className='text-sm'>Hide footer</span>
        </label>
      </div>


    </ModalWrapper>
  )
}

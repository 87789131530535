import ModuleWrapper from '../modules/ModuleWrapper.js'
import LayoutBase from './LayoutBase'

export default function LayoutCustom ({ modules, customLayout, onUpdate, ...props }) {
  return (
    <LayoutBase cols={16} rows={9} {...props}>
      {customLayout.map((box, boxId) => (
        <div
          key={boxId}
          className={`grid-flow-row col-start-${box.colStart} col-span-${box.colSpan} row-start-${box.rowStart} row-span-${box.rowSpan}`}
        >
          <ModuleWrapper
            index={boxId}
            module={modules[boxId]}
            onUpdate={onUpdate} {...props}
          />
        </div>
      ))}
    </LayoutBase>
  )
}

import BrandingColours from '../../consts/branding.js'
import getContrastingColor from '../../utils/getContrastingColor.js'
import UpgradeButton from '../util/UpgradeButton.js'
import { useIsPaidUser } from '../util/useIsPaidUser.js'

export function ModuleViewer ({ settings }) {
  const isPaidUser = useIsPaidUser()
  const textColor = getContrastingColor(settings?.primaryColour ? settings?.primaryColour : BrandingColours.Primary)
  return (
    <div
      style={{
        color: textColor,
      }}
      className="flex flex-col items-center justify-center text-center h-full p-8 space-y-4"
    >
      <p className="text-current font-semibold text-2xl">Oh hey 👋</p>
      <p className="font-semibold">Welcome to Dashmaster 2k</p>
      <p className="text-sm">Use the <code className='p-1 bg-gray-900 text-white rounded'>Edit</code> button to change the content of boxes or switch layouts below.</p>
      {!isPaidUser && <p className="text-gray-300 text-sm whitespace-nowrap"><UpgradeButton text='or Upgrade for even more features' /></p>}
    </div>
  )
}

export function ModuleEditor () {
  return (
    <div className="space-y-3"></div>
  )
}

import ModuleEditor from './ModuleEditor.js'
import ModuleViewer from './ModuleViewer.js'
import { isPlaceholder, tiles } from './moduleTypes.js'
import { Transition } from '@headlessui/react'
import React, { useState, useMemo, useCallback } from 'react'
import ModalWrapper from '../modals/ModalWrapper.js'
import BrandingColours from '../../consts/branding.js'

export default function ModuleWrapper ({ index, module = undefined, onUpdate, profile = false, disabled = false, settings = {} }) {
  const [editing, setEditing] = useState(false)
  const [hover, setHover] = useState(false)

  const showEditButton = !editing && !isPlaceholder(module?.type) && !disabled
  const onToggleEdit = useCallback(() => setEditing(!editing), [editing])
  const onCancelEdit = useCallback(() => setEditing(false), [])
  const onUpdateModule = useCallback((module) => {
    onUpdate(module, index)
    setEditing(false)
  }, [index, onUpdate])
  const moduleViewerMemo = useMemo(
    () => <ModuleViewer module={module} onUpdate={onUpdateModule} onToggleEdit={onToggleEdit} disabled={disabled} settings={settings} />,
    [module, onUpdateModule, onToggleEdit, disabled, settings],
  )
  const moduleEditorMemo = useMemo(
    () => <ModuleEditor module={module} onUpdate={onUpdateModule} onCancel={onCancelEdit} settings={settings} />,
    [module, onUpdateModule, onCancelEdit, settings],
  )

  if (profile) return (
    <div
      className="p-0.5 w-full h-full"
    >
      <div className='text-xs text-gray-900 flex justify-center items-center rounded h-full'
        style={{
          backgroundColor: tiles[module?.type]?.color,
        }}
      >
        <img
          className="h-4"
          src={tiles[module?.type]?.icon}
          alt={tiles[module?.type]?.label}
        />
      </div>
    </div>
  )
  return (
    <div
      style={{
        backgroundColor: settings?.primaryColour ? settings?.primaryColour : BrandingColours.Primary,
        borderColor: settings?.secondaryColour ? settings?.secondaryColour : BrandingColours.Secondary,
      }}
      className={'relative rounded-lg overflow-hidden border-2 text-gray-200 h-full w-full'}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      {showEditButton && (
        <Transition
          className="absolute z-10 left-3 top-3"
          show={hover}
          enter="transition"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="transition"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <button
            className="rounded text-white bg-gray-700 hover:bg-gray-600 shadow-lg px-4 py-2"
            onClick={onToggleEdit}
          >
            Edit
          </button>
        </Transition>
      )}
      <ModalWrapper
        show={editing}
        heading='Edit Module'
        hideFooter
        onClose={onCancelEdit}
      >
        {moduleEditorMemo}
      </ModalWrapper>
      {moduleViewerMemo}
    </div>
  )
}
